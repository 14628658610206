import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    listRichMenuType(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lineapi/list_richmenu_type', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getRichMenuType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lineapi/get_richmenu_type', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addRichMenuType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/add_richmenu_type', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editRichMenuType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/lineapi/edit_richmenu_type/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRichMenuType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/lineapi/delete_richmenu_type/${params.id}`, params.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDefaultRichMenuType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lineapi/get_default_richmenu_type', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    richMenuTypeOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/richmenu_type_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
