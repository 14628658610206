<template>
  <b-modal
    id="dialogue-select"
    :visible="showDialogueSelectModal"
    :title="$attrs.title"
    footer-class="d-flex justify-content-between"
    size="xl"
    centered
    hide-footer
    no-close-on-backdrop
    @change="(val) => $emit('update:show-dialogue-select-modal', val)"
  >
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
      <!-- Modal Header -->
      <template #modal-header>
        <h5 class="modal-title">
          {{ $attrs.title }}
        </h5>
        <div class="modal-actions">
          <feather-icon
            icon="XIcon"
            class="ml-1 cursor-pointer"
            @click="(busy === false) ? onDiscard() : false"
          />
        </div>
      </template>

      <!-- Modal Footer -->
      <template #modal-footer>
        <!-- Footer: Left Content -->
        <div />

        <!-- Footer: Right Content -->
        <div>
          <!-- Discard -->
          <feather-icon
            icon="XCircleIcon"
            size="17"
            class="ml-75 cursor-pointer"
            @click="(busy === false) ? onDiscard() : false"
          />
        </div>
      </template>
      <!-- Modal: Body -->
      <div>
        <div>
          <b-card
            class="p-0 m-0"
            style="margin-top: 0.8rem !important;"
          >
            <b-card-title style="margin-bottom: 0.4rem !important;">
              <div>
                <small
                  v-if="dialogueData.name !== undefined && dialogueData.name !== ''"
                >{{ `${$t('Select')} ${$t('Dialogue')}` }}</small>
                <b-alert
                  v-if="dialogueData.name !== undefined && dialogueData.name !== ''"
                  show
                  variant="success"
                  style="margin-top: 0.2rem;"
                >
                  <div class="alert-body">
                    <feather-icon
                      class="mr-25"
                      icon="CheckCircleIcon"
                    />
                    <span class="ml-25">{{ dialogueData.name }}</span>
                  </div>
                </b-alert>
              </div>
            </b-card-title>

            <div style="margin: 0rem 2rem 2rem 2rem;">

              <!-- Table Top -->
              <b-row>

                <!-- Per Page -->
                <b-col
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <label>{{ $t('Entries') }}</label>
                  <v-select
                    v-model="perPage"
                    :options="perPageOption"
                    :clearable="false"
                    class="per-page-selector d-inline-block ml-50 mr-1"
                  />
                </b-col>

                <b-col
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <div
                    class="d-flex align-items-center justify-content-end w-100 mt-1 mt-md-0"
                  >
                    <b-form-input
                      v-model="q"
                      class="d-inline-block mr-1"
                      :placeholder="`${$t('Search')}...`"
                    />
                  </div>
                </b-col>
              </b-row>

            </div>
            <b-table
              ref="refTable"
              :items="dialogueList"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="sortBy"
              show-empty
              hover
              empty-text="No matching records found"
              :sort-desc.sync="isSortDirDesc"
              class="position-relative"
              selectable
              select-mode="multi"
            >
              <!-- Column: ID -->
              <template #cell(id)="data">
                <div style="margin-top: 0.3rem;">
                  <b-list-group>
                    <b-list-group-item
                      style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                    >
                      <span style="font-size: 14px;">{{ $t('Name') }}</span>
                      <span style="margin-right: 0.6rem;">
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="14"
                        />
                      </span>
                      <span style="margin-right: 0.6rem;">
                        <feather-icon
                          icon="TagIcon"
                          size="14"
                        />
                      </span>
                      <span style="font-size: 14px;">{{ `${data.item.name}` }}</span>
                    </b-list-group-item>
                  </b-list-group>
                </div>
                <div style="margin-top: 0.3rem;">
                  <b-list-group>
                    <b-list-group-item
                      style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                    >
                      <span style="margin-right: 0.4rem;">
                        {{ $t('Create Date/Time') }}
                      </span>
                    </b-list-group-item>
                    <b-list-group-item
                      style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                    >
                      <span style="margin-right: 0.4rem;">
                        <feather-icon
                          icon="CalendarIcon"
                          size="14"
                        />
                      </span>
                      <span style="font-size: 14px;">
                        {{ data.item.create_date_show }}
                      </span>
                      <span style="margin-left: 1rem; margin-right: 0.4rem;">
                        <feather-icon
                          icon="ClockIcon"
                          size="14"
                        />
                      </span>
                      <span style="font-size: 14px;">
                        {{ data.item.create_time_show }}
                      </span>
                    </b-list-group-item>
                  </b-list-group>
                </div>
                <div style="margin-top: 0.3rem;">
                  <b-list-group>
                    <b-list-group-item
                      style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                    >
                      <span style="margin-right: 0.4rem;">
                        {{ $t('Update Date/Time') }}
                      </span>
                    </b-list-group-item>
                    <b-list-group-item
                      style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                    >
                      <span style="margin-right: 0.4rem;">
                        <feather-icon
                          icon="CalendarIcon"
                          size="14"
                        />
                      </span>
                      <span style="font-size: 14px;">
                        {{ data.item.update_date_show }}
                      </span>
                      <span style="margin-left: 1rem; margin-right: 0.4rem;">
                        <feather-icon
                          icon="ClockIcon"
                          size="14"
                        />
                      </span>
                      <span style="font-size: 14px;">
                        {{ data.item.update_time_show }}
                      </span>
                    </b-list-group-item>
                  </b-list-group>
                </div>
                <div style="margin-top: 0.3rem;">
                  <b-list-group>
                    <b-list-group-item
                      style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                    >
                      <span style="margin-right: 0.6rem;">
                        <feather-icon
                          icon="HashIcon"
                          size="14"
                        />
                      </span>
                      <span style="font-size: 14px;">
                        <b-badge
                          pill
                          :variant="(skin === 'dark')? `light-info` : `info` "
                          class="text-capitalize"
                        >
                          {{ data.item.id }}
                        </b-badge>
                      </span>
                    </b-list-group-item>
                  </b-list-group>
                </div>
                <div
                  v-if="data.item.context.length > 0"
                  style="margin-top: 0.3rem;"
                >
                  <b-list-group>
                    <b-list-group-item
                      style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                    >
                      <span style="font-size: 14px;">{{ $t('Context') }}</span>
                      <span style="margin-right: 0.6rem;">
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="14"
                        />
                      </span>
                      <span style="font-size: 14px;">
                        <b-badge
                          v-for="(itemContext, indexContext) in data.item.context"
                          :key="indexContext"
                          pill
                          :variant="(skin === 'dark')? `light-success` : `success` "
                          class="text-capitalize"
                          style="margin-right: 0.2rem;"
                        >
                          {{ itemContext }}
                        </b-badge>
                      </span>
                    </b-list-group-item>
                  </b-list-group>
                </div>
                <div
                  style="margin-top: 0.3rem;"
                >
                  <b-list-group>
                    <b-list-group-item
                      style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                    >
                      <span style="font-size: 14px;">{{ `${$t('Data')} ${$t('Postback')}` }}</span>
                      <span style="margin-right: 0.6rem;">
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="14"
                        />
                      </span>
                      <span style="font-size: 14px;">
                        <b-badge
                          pill
                          :variant="(skin === 'dark')? `light-info` : `info` "
                          class="text-capitalize"
                          style="margin-right: 0.2rem;"
                        >
                          {{ data.item.postback }}
                        </b-badge>
                      </span>
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </template>

              <!-- Column: message -->
              <template #cell(message)="data">
                <div
                  style="margin-top: 0.3rem;"
                >
                  <line-chat-message :data="data.item.message" />
                </div>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <div
                  style="margin-top: 0.3rem;"
                >
                  <b-button
                    v-if="checkSelect(data.item)"
                    size="sm"
                    variant="primary"
                    style="margin-left: 0rem;"
                    @click.stop="onSelect(data.item)"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                      size="14"
                      style="margin-right: 0.4rem;"
                    />
                    <span class="align-middle">{{ $t('Select') }}</span>
                  </b-button>

                  <b-button
                    v-else
                    size="sm"
                    variant="outline-success"
                    style="margin-left: 0rem;"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                      size="14"
                      style="margin-right: 0.4rem;"
                    />
                    <span class="align-middle">{{ $t('Selected') }}</span>
                  </b-button>
                </div>
              </template>

            </b-table>
            <div class="mx-2 mb-2">
              <b-row>

                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted">
                    {{ $t('Showing {from} to {to} of {of} entries',
                          {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                    }}
                  </span>
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >

                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalItem"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>

                </b-col>

              </b-row>
            </div>
          </b-card>
        </div>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BCard,
  BCardTitle,
  BTable,
  BRow,
  BCol,
  BPagination,
  BAlert,
  BListGroup,
  BListGroupItem,
  BBadge,
  BFormInput,
  BOverlay,
} from 'bootstrap-vue'
import {
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import LineChatMessage from '@core/components/line-chat-message/LineChatMessage.vue'
import { $themeConfig } from '@themeConfig'
import dialogueStoreModule from './dialogueStoreModule'

export default {
  components: {
    LineChatMessage,

    BButton,
    BCard,
    BCardTitle,
    BTable,
    BRow,
    BCol,
    BPagination,
    BAlert,
    BListGroup,
    BListGroupItem,
    BBadge,
    BFormInput,
    BOverlay,

    vSelect,
  },
  model: {
    prop: 'showDialogueSelectModal',
    event: 'update:show-dialogue-select-modal',
  },
  props: {
    showDialogueSelectModal: {
      type: Boolean,
      required: true,
    },
    dialogueData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      filterSelect: JSON.parse(localStorage.getItem(`${$themeConfig.app.session}-filter-dialogue-select`)),
      skin: store.state.appConfig.layout.skin,
      showOverlay: false,
      busy: false,
      tableColumns: [],
      perPage: 10,
      totalItem: 0,
      currentPage: 1,
      perPageOption: [2, 5, 10, 25, 50, 100],
      sortBy: 'id',
      isSortDirDesc: true,
      locale: 'th',
      q: '',
      shallShowDialogueSelectFormModal: false,
      dialogueList: [],
    }
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.$refs.refTable ? this.$refs.refTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalItem,
      }
    },
    lineOa() {
      const value = store.state.lineapi.lineOa
      if (value === '' || value === undefined) {
        return ''
      }
      return JSON.parse(value)
    },
    lineOaId() {
      const value = this.lineOa.id
      if (value === '' || value === undefined) {
        return ''
      }
      return value
    },
  },
  watch: {
    currentPage() {
      this.refetchData()
    },
    perPage() {
      this.refetchData()
    },
    locale() {
      this.refetchData()
    },
    q() {
      this.refetchData()
    },
    dialogueData() {
      this.getListDialogueSelect()
    },
  },
  mounted() {
    const msgList = {
      id: `${this.$i18n.t('Name')} » ${this.$i18n.t('ID')}`,
      message: `${this.$i18n.t('Dialogue')} » (${this.$i18n.t('Chat Message')} » ${this.$i18n.t('First')} )`,
      actions: this.$i18n.t('Actions'),
    }
    this.tableColumns = [
      { key: 'id', sortable: true, label: msgList.id },
      { key: 'message', sortable: false, label: msgList.message },
      { key: 'actions', label: msgList.actions },
    ]
  },
  methods: {
    getListDialogueSelect() {
      this.showOverlay = true
      const params = {
        dialogueId: this.dialogueData.id,
        perPage: this.perPage,
        page: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        language: this.locale,
        q: this.q,
        lineOaId: this.lineOaId,
      }
      store
        .dispatch('dialogue-store/getListDialogueSelect', params)
        .then(response => {
          const { list, total } = response.data
          this.dialogueList = list
          this.totalItem = total
          this.showOverlay = false
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    onDiscard() {
      this.$emit('discard-dialogue-select')
      this.$emit('update:show-dialogue-select-modal', false)
    },
    refetchData() {
      this.getListDialogueSelect()
    },
    onSelect(item) {
      this.busy = true
      this.$emit('update-dialogue-select', { select: item, dialogue: this.dialogueData })
      this.$emit('update:show-dialogue-select-modal', false)
      this.busy = false
    },
    checkSelect(item) {
      if (parseInt(item.id, 10) === parseInt(this.dialogueData.id, 10)) {
        return false
      }
      return true
    },
  },
  setup() {
    if (!store.hasModule('dialogue-store')) store.registerModule('dialogue-store', dialogueStoreModule)
    onUnmounted(() => {
      if (store.hasModule('dialogue-store')) store.unregisterModule('dialogue-store')
    })

    return {}
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.dialogue-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
    .swal2-popup {
      background-color: $theme-dark-body-bg !important;
    }
  }
  .table.b-table > tbody .b-table-row-selected.table-active td {
    background-color: $theme-dark-table-active-bg;
  }
  .table.b-table.table-hover > tbody > tr.table-active:hover td,
  .table.b-table.table-hover > tbody > tr.table-active:hover th {
    background-image: linear-gradient($theme-dark-table-active-hover-bg, $theme-dark-table-active-hover-bg);
  }
}
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
</style>
